import React from "react";
import ProjectsCard from "../../components/ProjectsCard";
const items = [
  {
    title: "Software Engineer- R&D",
    content: "Calicut, India",
    link: "software-engineer",
  },
  {
    title: "Growth Strategist and Innovation Architect",
    content: "Bangalore, India",
    link: "growth-strategist",
  },
  /* {
    title: "Office and Culture Manager",
    content: "Bangalore, India",
    link: "office-manager",
  }, */
];

const Projects = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-xs-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17 "
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-9 ">
                We value our people — we invest in them, build an inclusive
                culture and provide a supportive environment to empower their
                growth. As we scale our team we’re looking to meet more
                creative, outcome-driven individuals, who bring integrity,
                honesty, and professionalism to the table.
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div
          className="text-center mb-7 mb-lg-17 mt-xs-5 mt-xl-2"
          data-aos="fade-up"
          data-aos-duration={600}
        >
          <p>
            Check out our current vacancies—you could be looking at your next
            move.
          </p>
        </div>
        <div className="col justify-content-center mt-5">
          {items.map((item, index) => (
            <div
              className="col-xl-9 col-lg-8 col-md-8 col-sm-11 col-xs-12 mb-4 mb-lg-10 m-2"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <ProjectsCard options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
