import React from "react";
import imgBg from "../../assets/image/media/careers/png/CareersHero.png";
const Hero = () => {
  return (
    <div
      className="pt-24 pt-md-26 pt-lg-30 pb-14 pb-md-18 pb-lg-24 bg-images"
      css={`
        background-image: url(${imgBg});
      `}
    >
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-11 pt-4">
            <div
              className=" mb-11 mb-lg-24 text-center"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-9">
                Our team is the core reason for our success
              </h1>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
