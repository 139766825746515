import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import CtaSec from "../sections/homepage/cta";
import Hero from "../sections/careers/Hero";
import Projects from "../sections/careers/Projects";

const Careers = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <Link to="/contact">
                <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                  Let's talk
                </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero />
        <Projects />
        <CtaSec />
      </PageWrapper>
    </>
  );
};
export default Careers;
