import { Link } from "gatsby";
import React from "react";
const ProjectsCard = ({ options }) => {
  return (
    <div className="d-xs-flex m-1">
      <div className="p-5 mt-3 mt-lg-0 mb-3  ">
        <Link
          className=""
          to={`/${options.link}/`}
          role="button"
          aria-expanded="false"
        >
          <h3 className="font-size-7 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pt-3 pr-lg-8 pr-xl-5">
            <u>{options.title}</u>
          </h3>
        </Link>
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            className=" mr-2 "
            viewBox="0 0 384 512"
          >
            <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
          </svg>
          <p className="font-size-5 mb-1">{options.content}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;
